import { CompanyInformationForm, PageWithIntro, WizardLayout } from 'components';
import { getFormState, handleFormSubmit } from '../../../utilities/formstate';
import { LICENSE_DETAILS_FORM_NAME as FORM_NAME } from '../../../utilities/licenses';
import React from 'react';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import { companyInformationStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { useTracking } from '../../../utilities/hooks';

const CompanyInformationPage = () => {
  const formState = getFormState(FORM_NAME);
  const stepCompleted = stepIsComplete(companyInformationStep);

  useTracking('CompanyInformation');

  return (
    <PageWithIntro step={companyInformationStep}>
      <WizardLayout text>
        <h1>Confirm License Information</h1>
        <WistiaVideo id={companyInformationStep.intro.videoID} />
        <CompanyInformationForm
          stepCompleted={stepCompleted}
          initialValues={formState.values}
          working={false}
          onSubmit={handleFormSubmit(formState)}
        />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default CompanyInformationPage;
